<template>
    <button @click="increment">
        {{ state.count }}-{{ state.double }}-{{ triple }}
    </button>
</template>

<script>
import { reactive, computed, watchEffect } from 'vue';
export default {
    setup() {
        const state = reactive({
            count: 0,
            double: computed(() => state.count * 2),
        });

        function increment() {
            state.count++;
        }
        watchEffect(() => {
            console.log(state.count);
        });
        return {
            state,
            increment,
        };
    },
    computed: {
        triple() {
            return this.state.count * 3;
        },
    },
};
</script>

<style></style>
